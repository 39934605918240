import React from 'react';

const Terms: React.FC = () => {
    return (
        <div style={{ padding: '2rem' }}>
            <h1>Terms of Service</h1>
            <p>This is where your terms of service content will go.</p>
            {/* Add your terms of service content here */}
        </div>
    );
};

export default Terms;
