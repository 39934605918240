import React, {useEffect, useState} from 'react';
import {useAuth} from '../auth/AuthProvider';
import {useNavigate} from 'react-router-dom';
import api from '../api'; // Import your axios instance
import Header from '../components/Header';
import Footer from '../components/Footer';
import './UsernameSetup.css';
import axios from "axios";

const UsernameSetup: React.FC = () => {
    const {currentUser, logOut} = useAuth();
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState<{ text: string; color: string } | null>({
        text: '',
        color: 'red'
    });
    const navigate = useNavigate();

    useEffect(() => {
        if (!currentUser) {
            navigate('/creator/sign-up');
            return;
        }

        const checkUsername = async () => {
            try {
                const response = await api.get(`/creator/username/${currentUser!.email}`);
                console.log("For this user, the username is already set.");
                if (response.data?.username) {
                    navigate(`/${response.data.username}`);
                }
            } catch (error) {
                console.error('Failed to check username:', error);
            }
        };

        checkUsername();
    }, [currentUser, navigate]);

    const handleError = (error: any, defaultMessage: string) => {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 404) {
                setStatusMessage({text: 'Username not found. You can set a new one.', color: 'red'});
            } else if (error.response.status === 409) {
                setStatusMessage({text: 'Username is already taken. Please choose another.', color: 'red'});
            } else if (error.response.status === 400) {
                setStatusMessage({text: 'Invalid request. Please check your input.', color: 'red'});
            } else if (error.response.status >= 500) {
                setStatusMessage({text: 'Server error. Please try again later.', color: 'red'});
            } else {
                setStatusMessage({text: defaultMessage, color: 'red'});
            }
        } else {
            setStatusMessage({text: 'Network or unknown error. Please try again later.', color: 'red'});
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        setStatusMessage(null);

        try {
            await api.post('/creator/username', {
                email: currentUser?.email,
                username,
            });
            setStatusMessage({text: 'Username saved successfully!', color: 'green'});
            setTimeout(() => navigate(`/${username}`), 1000);
        } catch (error) {
            handleError(error, 'Failed to save username. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Header/>
            <div className="username-setup-container">
                <div className="username-setup-box">
                    <h2 className="username-setup-heading">Choose your username</h2>
                    <p className="username-setup-subtext">Your profile will be available at:</p>
                    <form onSubmit={handleSubmit} className="username-setup-form">
                        <div className="username-bar">
                            <span className="username-prefix">solt.app/</span>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="your username"
                                required
                                className="username-input"
                            />
                        </div>
                        <p className="username-info-text">The username cannot be changed once saved.</p>
                        {statusMessage && (
                            <p className={`status-message`} style={{color: statusMessage.color}}>
                                {statusMessage.text}
                            </p>
                        )}
                        <button type="submit" className="username-submit-button" disabled={loading}>
                            {loading ? <span className="loading-spinner"></span> : 'Save & Continue'}
                        </button>
                    </form>
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default UsernameSetup;
