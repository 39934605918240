import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; // CSS file for styling

import logo from '../assets/logo.png'; // Import your logo

const Header: React.FC = () => {
    return (
        <header className="header">
            <div className="header-content">
                <Link to="/">
                    <img src={logo} alt="Solt Logo" className="header-logo" />
                </Link>
            </div>
        </header>
    );
};

export default Header;
