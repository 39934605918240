import React from 'react';

const Privacy: React.FC = () => {
    return (
        <div style={{ padding: '2rem' }}>
            <h1>Privacy Policy</h1>
            <p>This is where your privacy policy content will go.</p>
            {/* Add your privacy policy content here */}
        </div>
    );
};

export default Privacy;
