import React, { useEffect } from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import SignUp from './pages/SignUp';
import UsernameSetup from './pages/UsernameSetup';
import Profile from './pages/Profile';
import { useAuth } from './auth/AuthProvider';
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Home from "./pages/Home";

const App: React.FC = () => {
    // const { currentUser } = useAuth();
    //
    // const RedirectToExternal = () => {
    //     useEffect(() => {
    //         window.location.replace('https://solt.app');
    //     }, []);
    //
    //     return null; // No UI needed, just redirect
    // };

    return (
        <Routes>
            <Route path="/creator/sign-up" element={<SignUp />} />
            <Route
                path="/creator/setup-username" element={<UsernameSetup/>}
                // element={currentUser ? <UsernameSetup /> : <SignUp />}
            />
            <Route path="/:username" element={<Profile />} />
            {/*<Route path="/" element={<RedirectToExternal />} />*/}
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/" element={<Home />} />

            {/* Wildcard route: redirects any undefined route to Home */}
            {/*<Route path="*" element={<Navigate to="/" />} />*/}
        </Routes>
    );
};

export default App;
