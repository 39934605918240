import React from 'react';
import {Link} from 'react-router-dom';

import soltLogo from '../assets/logo.png';
import soltApp from '../assets/solt-app.png';

import chefImage from '../assets/creators-image.png';

import './Home.css';
import Footer from "../components/Footer";


function Home() {

    return (
        <>
            {/*Intro section*/}
            <header className="App-header">

                <div className="logo-container">
                    <img src={soltLogo} alt="Solt App Logo"/>
                </div>

                {/*<div className="content">*/}
                {/*    <h1>The place for foodies and food reviewers</h1>*/}
                {/*    <h3 style={{fontWeight: "normal"}}>Solt is a link-in-bio tool to post your favorite dishes and grow*/}
                {/*        your food community.</h3>*/}

                {/*    <div className="buttons">*/}
                {/*        <a className="button" href="mailto:hello@solt.app" target="_blank"*/}
                {/*           rel="noopener noreferrer">Coming soon</a>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="phone-image">*/}
                {/*    <img src={soltApp} alt="Solt Webapp"/>*/}
                {/*</div>*/}
            </header>


            <section className="blog">
                <div className="content">
                    <h1>The place for foodies and food reviewers</h1>
                    <h3 style={{fontWeight: "normal"}}>Solt is a link-in-bio tool to post your favorite dishes and grow
                        your food community.</h3>

                    <div className="buttons">
                        {/*<Link className="button" to="/creator/sign-up">Get started</Link>*/}
                        {/*<a className="button" href="mailto:hello@solt.app" target="_blank"*/}
                        {/*   rel="noopener noreferrer">Get started</a>*/}
                    </div>
                </div>
                <div className="phone-image">
                    <img src={soltApp} alt="Solt Webapp"/>
                </div>
            </section>

            {/* Partners Section */}
            <section className="blog">
                <div className="blog-image">
                    <img src={chefImage} alt="Solt Partners Image"/>
                </div>

                <div className="blog-content">
                    <h2>Do you like to create food content?</h2>
                    <p>Create a Solt profile and start posting dish recommendations from restaurants. </p>
                    <p>Your profile will be available at solt.app/@username</p>
                    <p>Build your food community, monetize your cravings.</p>

                    <Link className="button" to="/creator/sign-up">Create your profile</Link>
                    {/*<a className="button" href="mailto:hello@solt.app" target="_blank"*/}
                    {/*   rel="noopener noreferrer">hello@solt.app</a>*/}
                    {/*<a className="button" href="tel:0040741333306" target="_blank" rel="noopener noreferrer">+40741333306</a>*/}
                </div>


            </section>

            {/*<div className={"footer-bar"}/>*/}

            <Footer/>
            {/*<footer className="footer">*/}
            {/*    <div className="footer-left">*/}
            {/*        <img src={soltLogo} alt="Solt App Logo" className="footer-logo"/>*/}
            {/*        <p>The place for foodies and food reviewers</p>*/}
            {/*    </div>*/}

            {/*    <div className="footer-right">*/}
            {/*        <div className="social-links">*/}
            {/*            /!*<a href="https://www.tiktok.com/@solt.app" target="_blank" rel="noopener noreferrer"><img src={tiktokIcon} alt="TikTok" /></a>*!/*/}
            {/*            /!*<a href="https://www.instagram.com/solt.app" target="_blank" rel="noopener noreferrer"><img src={instagramIcon} alt="Instagram"/></a>*!/*/}
            {/*        </div>*/}
            {/*        <p>Contact: <a href="mailto:hello@solt.app">hello@solt.app</a></p>*/}
            {/*        <Link to="/terms">Terms and Conditions</Link>*/}
            {/*        <br/>*/}
            {/*        <Link to="/privacy">Privacy Policy</Link>*/}
            {/*    </div>*/}
            {/*</footer>*/}

        </>
    );
}

export default Home;
