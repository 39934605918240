import axios, { AxiosResponse } from 'axios';

const production = true;
const SERVER_URL = production ? 'https://solt-creators-435312.ew.r.appspot.com/api' : 'http://localhost:8080/api';

const api = axios.create({
    baseURL: SERVER_URL
});

// Fetch creator by username
export const getCreatorByUsername = async (username: string): Promise<any | 'error' | 'not-found'> => {
    console.log('getCreatorByUsername');
    try {
        const response = await api.get(`/creator/profile/${username}`); // Use the correct route
        return response.data; // Return the creator data
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 400) {
                console.error('Invalid request: Username parameter is required.');
                return 'error'; // Return 'error' for bad request
            } else if (error.response.status === 404) {
                console.error('Creator not found:', error.response.data.message);
                return 'not-found'; // Return 'not-found' if the creator does not exist
            } else if (error.response.status >= 500) {
                console.error('Server error:', error.response.data.message);
                return 'error'; // Return 'error' for server-side issues
            }
        }
        console.error('Network or unknown error fetching creator:', error);
        return 'error'; // Return 'error' for network or other unknown issues
    }
};

// Update creator profile
export const updateCreator = async (
    email: string,
    updateData: {
        name?: string;
        bio?: string;
        postsTitle?: string;
        profileImage?: string;
    }
): Promise<boolean | 'error' | 'not-found'> => {
    try {
        // Include the email in the body of the request, along with other update fields
        const response = await api.put(`/creator/profile`, {
            email, // Send email in the request body as the server expects
            ...updateData, // Other fields to be updated
        });

        if (response.status === 200) {
            return true;  // Profile updated successfully
        }
        return 'error';  // Fallback error
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 404) {
                return 'not-found';  // Creator not found
            } else if (error.response.status >= 500) {
                console.error('Server error while updating creator profile:', error.response.data.message);
                return 'error';  // Server error
            }
        }
        console.error('Network or unknown error updating creator profile:', error);
        return 'error';  // Generic network or unknown error
    }
};
// Set profile picture for creator using creatorUID
export const setProfilePicture = async (file: File, creatorId: string): Promise<string | 'invalid-file' | 'upload-error'> => {
    const formData = new FormData();
    formData.append('file', file);  // Attach the file to the request

    try {
        const response = await api.post('/creator/profile/image/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            params: {
                creatorId,  // Pass the creator's Firebase UID (creatorUID) as a query parameter
            },
        });

        if (response.status === 200 && response.data.imageUrl) {
            return response.data.imageUrl;  // Return the uploaded image URL
        }
        return 'upload-error';  // If something unexpected happened
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 400) {
                console.error('Invalid file format or bad request:', error.response.data.message);
                return 'invalid-file';  // Return specific error for invalid file format
            } else if (error.response.status >= 500) {
                console.error('Server error during file upload:', error.response.data.message);
                return 'upload-error';  // Return specific error for server issues
            }
        }
        console.error('Network or unknown error uploading profile picture:', error);
        return 'upload-error';  // Return generic error for network or unknown issues
    }
};


export const checkCreatorExists = async (email: string): Promise<boolean | 'error' | 'creator-not-found'> => {
    try {
        const response = await api.get(`/creator/${email}`);
        return response.status === 200; // Returns true if the creator exists
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 400) {
                console.error('Invalid request: Email parameter is required.');
                return 'error'; // Return 'error' for bad request
            } else if (error.response.status === 404) {
                console.error('Creator not found:', error.response.data.message);
                return 'creator-not-found'; // Return specific value when the creator is not found
            } else if (error.response.status >= 500) {
                console.error('Server error:', error.response.data.message);
                return 'error'; // Return 'error' for server-side issues
            }
        }
        console.error('Network or unknown error checking if creator exists:', error);
        return 'error'; // Return 'error' for network or other unknown issues
    }
};


export const createCreator = async (email: string, name: string, firebaseUID: string): Promise<boolean | 'error' | 'conflict'> => {
    try {
        const response = await api.post('/creator', {
            email,
            name,
            firebaseUID,
            privacyPolicyAccepted: true,
            termsAccepted: true,
        });

        return response.status === 201; // Return true if the creator was successfully created
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 400) {
                console.error('Invalid request: Missing required fields.');
                return 'error'; // Return 'error' for bad request
            } else if (error.response.status === 409) {
                console.error('Conflict: Creator with this email already exists.');
                return 'conflict'; // Return specific value when there's a conflict (duplicate creator)
            } else if (error.response.status >= 500) {
                console.error('Server error:', error.response.data.message);
                return 'error'; // Return 'error' for server-side issues
            }
        }
        console.error('Network or unknown error creating creator:', error);
        return 'error'; // Return 'error' for network or other unknown issues
    }
};


export const checkUsernameExists = async (email: string): Promise<string | null | 'error' | 'creator-not-found'> => {
    try {
        const response = await api.get(`/creator/username/${email}`);
        return response.data?.username || null; // Return the username if it exists
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 400) {
                console.error('Invalid request: Email parameter is required.');
                return 'error'; // Return 'error' for bad request
            } else if (error.response.status === 404 && error.response.data.message === 'Creator not found') {
                console.error('Creator not found:', error.response.data.message);
                return 'creator-not-found'; // Return specific value when the creator is not found
            } else if (error.response.status === 404 && error.response.data.message === 'Username not found') {
                return null; // Return null if the username does not exist
            } else if (error.response.status >= 500) {
                console.error('Server error:', error.response.data.message);
                return 'error'; // Return 'error' for server-side issues
            }
        }
        console.error('Network or unknown error checking if username exists:', error);
        return 'error'; // Return 'error' for network or other unknown issues
    }
};

export const getPostsByUsername = async (username: string): Promise<any[] | 'error' | 'not-found' | 'missing-username'> => {
    try {
        const response = await api.get(`/creator/posts/${username}`);
        return response.data.posts || []; // Return the list of posts or an empty array
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 400 && error.response.data.message === 'Username is required') {
                console.error('Error: Username is required.');
                return 'missing-username'; // Return 'missing-username' if the username was missing
            } else if (error.response.status === 404) {
                console.error('Posts not found for this username.');
                return 'not-found'; // Return specific value when no posts are found
            } else if (error.response.status >= 500) {
                console.error('Server error while fetching posts:', error.response.data.message);
                return 'error'; // Return 'error' for server-side issues
            }
        }
        console.error('Network or unknown error fetching posts:', error);
        return 'error'; // Return 'error' for network or other unknown issues
    }
};

// Add a new post by username
export const addPost = async (
    username: string,
    post: { title: string; description: string; images: string[]; location: string }
): Promise<boolean | 'error' | 'missing-fields' | 'not-found'> => {
    try {
        const response = await api.post(`/creator/posts/${username}`, post);

        if (response.status === 201) {
            return true;
        }
        return 'error'; // Fallback error
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            const { status, data } = error.response;
            if (status === 400 && data.message === 'Missing required fields: title, description, location, and at least one image') {
                return 'missing-fields';
            } else if (status === 404 && data.message === 'Creator not found') {
                return 'not-found';
            }
        }
        return 'error'; // Fallback error
    }
};

// Update an existing post by username and postId
export const updatePost = async (
    username: string,
    postId: string,
    post: { title: string; description: string; images: string[]; location: string }
): Promise<boolean | 'error' | 'missing-fields' | 'not-found' | 'post-not-found'> => {
    try {
        const response = await api.put(`/creator/posts/${username}/${postId}`, post);

        if (response.status === 200) {
            return true;
        }
        return 'error'; // Fallback error
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            const { status, data } = error.response;
            if (status === 400 && data.message === 'Missing required fields: title, description, location, and at least one image') {
                return 'missing-fields';
            } else if (status === 404 && data.message === 'Creator not found') {
                return 'not-found';
            } else if (status === 404 && data.message === 'Post not found') {
                return 'post-not-found';
            }
        }
        return 'error'; // Fallback error
    }
};

export const uploadImage = async (file: File, creatorId: string): Promise<string | 'invalid-file' | 'upload-error'> => {
    const formData = new FormData();
    formData.append('file', file); // Only append the file
    console.log('uploadImage');

    try {
        const response = await api.post('/creator/posts/image/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            params: {
                creatorId,  // Pass the creator-id as a query parameter
            },
        });

        // Check for a successful response and a valid URL
        if (response.status === 200 && response.data && response.data.url) {
            return response.data.url;
        }

        // Handle unexpected server responses
        console.error('Unexpected server response:', response);
        return 'upload-error'; // In case of unexpected server response
    } catch (error: any) {
        if (error.response) {
            // Server responded with an error status code
            const status = error.response.status;

            if (status === 400) {
                console.error('Invalid file format or bad request:', error.response.data.message);
                return 'invalid-file'; // Return specific error for invalid file
            } else if (status === 500) {
                console.error('Server error during file upload:', error.response.data.message);
                return 'upload-error'; // Return specific error for server issues
            }
        } else if (error.request) {
            // Request was made but no response was received
            console.error('No response from server:', error.request);
            return 'upload-error'; // Handle network or timeout issues
        } else {
            // Something else happened while setting up the request
            console.error('Error in uploadImage function:', error.message);
            return 'upload-error';
        }
    }

    // Return a fallback in case something goes wrong unexpectedly
    return 'upload-error';
};
export default api;
