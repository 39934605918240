import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // CSS file for styling

import logo from '../assets/logo.png'; // Import your logo

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-divider"></div>

                <div className="footer-left">
                    <img src={logo} alt="Solt Logo" className="footer-logo"/>
                    <p className="footer-message">The place for foodies and food reviewers</p>
                </div>
                <div className="footer-links">
                    <p>Say hi: <a className="footer-email" href="mailto:hello@solt.app">hello@solt.app</a></p>

                    <span>
                    <Link to="/privacy">Privacy</Link>
                        <span> & </span>
                    <Link to="/terms">Terms</Link>
                    </span>
                </div>
            </div>
            {/*<div className="footer-divider"></div>*/}
            <p className="footer-bottom">
                Made with taste in Europe. All rights reserved.
                <br/>
                ©Solt
            </p>
        </footer>
    );
};

export default Footer;
